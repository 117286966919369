/* This file is for your main application css. */
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

body {
  margin: 0 !important;
}

strong,
b {
  font-weight: 600;
}

em {
  font-style: oblique;
}

.info,
.error {
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  position: relative;
  z-index: 99999;
  float: left;
  width: 100%;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.info p,
.error p {
  padding: 10px;
}

.info a,
.error a {
  color: #ff5056;
  cursor: pointer;
}

header .logo {
  max-width: 400px;
  width: 100%;
}

.subheader {
  font-size: 20px;
  padding: 15px 0 25px;
  display: inline-block;
}

.small-header h1 {
  margin-top: 10px !important;
}

.small-header .logo {
  float: left;
  width: auto !important;
  height: 75px !important;
}

.clickable {
  cursor: pointer;
}

.editable {
  color: inherit !important;
}

.editable:hover {
  opacity: 0.6;
}

.ql-toolbar,
.quill {
  margin-left: 10px !important;
  width: 100% !important;
}

.quill {
  color: #ddd;
}

.quill strong,
.quill b {
  font-weight: 600;
}

.quill em,
.quill i {
  font-style: oblique;
}

.admin-nav {
  position: relative;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
}

.admin-nav i {
  font-size: 16px;
  margin: 2px 10px;
}

.admin-nav ul {
  float: left;
  width: 100%;
}

.admin-nav li {
  float: left;
  padding: 10px;
}

.admin-nav .pull-right {
  margin-right: -10px;
}

.admin-nav a {
  color: #ccc;
  font-weight: 600;
}

.admin-nav a:hover {
  color: #fff;
}

.admin-nav a.current {
  color: #fff;
  cursor: default;
}

.admin-nav .email {
  color: #ff5056;
  margin-right: 10px;
}

.admin-gallery {
  margin-top: 10px;
}

.admin-gallery .image-container {
  position: relative;
  float: left;
  height: 200px;
  margin: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
}

.admin-gallery img {
  height: 200px;
}

.admin-gallery .btn {
  position: absolute;
  right: 0;
  font-size: 18px;
  background: rgba(0, 0, 0, 0.8);
  padding: 0 3px;
}

.dd-select-container,
.dd-options,
.dd-select {
  width: 100% !important;
}

.dd-container {
  margin-left: 10px;
  width: 100% !important;
}

.dd-options {
  background: #8a8888 !important;
  width: 100% !important;
}

.dd-select,
.dd-option-selected {
  background: #b3aeae !important;
}

.dd-selected,
.dd-option {
  height: 140px !important;
}

.dd-selected-image,
.dd-option-image {
  height: 120px !important;
  max-width: 100% !important;
}

.table {
  background: #000;
  padding: 10px;
  color: #ddd;
}

.table img {
  max-height: 120px !important;
}

.table .actions span {
  margin-left: 10px;
}

.text-right {
  text-align: right;
}

main {
  position: relative;
  float: left;
  width: 100%;
  /*margin-top: -220px;*/
}

main a {
  color: #fff;
  cursor: pointer;
}

#header::after {
  bottom: 87px !important;
}

#header .header_1 {
  top: -90px !important;
  padding: 90px 0 60px !important;
  margin-bottom: -90px !important;
  background-position: center bottom;
}

.mobile-contact {
  color: #fff;
  font-size: 18px;
}

.mobile-contact .call-info {
  margin: 10px 0;
}

.mobile-contact .fa-phone,
.mobile-contact .fa-envelope {
  margin-right: 10px;
}

#tabs {
  margin-top: 0 !important;
}

#tabs .image {
  display: inline;
  margin-bottom: 10px;
}

.about-section {
  display: inline-block;
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  background: #0c0c0c;
  padding-bottom: 15px;
}

.about-section .post8 {
  margin-top: 0;
}

.about-section h2 {
  margin: 10px 0;
}

.about-section img {
  height: 200px;
}

.about-section a {
  color: #ff5056;
  cursor: pointer;
}

.wrapper2 {
  position: relative;
}

a {
  cursor: pointer;
}

.phone-link {
  color: #fff;
}

.call-us {
  color: #fff !important;
  padding: 60px 0 0 ;
}

.table-description {
  padding: 10px 0;
}

b {
  font-weight: 600 !important;
}

i {
  font-style: oblique;
}

i.fa {
  font-style: normal;
}

.social-media-links a:nth-child(n+3)::before,
.social-media-links a:nth-child(n+3)::after {
  display: none !important;
}

.social-media-icon {
  font-size: 36px;
  margin: 5px;
}

.header-icon {
  font-size: 36px;
  margin-right: 10px;
}

h1,
h2,
h3,
h4 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

main h3 {
  font-size: 18px;
  color: #fff;
}

.ui-tabs-panel img {
  max-height: 300px;
}

.post2 .list1 {
  margin-top: 0 !important;
  color: #fff;
}

.post2 .list1 li {
  position: relative;
  padding-left: 15px;
}

.post2 .list1 li::before {
  position: absolute;
  content: '';
  display: block;
  background: url(/images/list1.png) no-repeat 0 0;
  width: 6px;
  height: 8px;
  top: 5px;
  left: 0;
}

.post2 .post2_wrapper1 {
  margin: 5px !important;
}

.post2 .post2_wrapper1 p {
  color: rgba(255, 255, 255, 0.9) !important;
}

.post2 p {
  color: #fff;
}

#phone-email {
  /*margin: 30px 0;*/
}

#order-a-casino br,
#contact-form br {
  display: none;
}

#order-a-casino {
  padding-top: 14px;
}

#contact-form {
  padding: 20px 0;
}

#contact-form p {
  padding: 10px 20px 0 0;
  line-height: 1.5;
}

#contact-form i.fa {
  margin: 5px 10px;
}

#contact-form ul {
  margin: 20px 0 5px;
  padding-left: 20px;
  list-style-type: square;
}

#contact-form input,
#contact-form textarea {
  margin: 2px 0 3px;
}

#contact-form button {
  margin-left: 0;
  background: #a72f33;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 0 1px 1px #787878;
}

#contact-form .logo {
  margin-top: 30px;
  width: 100%;
  height: auto;
}

.form-group {
  padding: 10px 0;
}

.form-group input,
.form-group textarea {
  width: 100%;
  margin-left: 10px;
  font-size: 16px;
}

.form-group span {
  margin-left: 10px;
  color: #ddd;
}

.form-group select {
  margin-left: 10px;
}

.form-group input[type=checkbox] {
  width: auto !important;
}

.form-group button {
  width: 100%;
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.form-group textarea {
  padding: 4px;
  max-width: 100%;
  min-height: 150px;
}

.control-label {
  color: #ddd;
}

footer {
  display: inline-block;
  width: 100%;
}

footer p {
  padding: 30px 0 0;
  color: #ccc;
}

footer a {
  color: #ff5056;
  cursor: pointer;
}

.mobile-contact .social-media-icon {
  font-size: 24px;
}

.tabs-wrapper {
  background: #000;
}

.tabs-wrapper ul {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.tab {
  background: #202020;
  box-shadow: 0px 0 1px 1px #787878;
  /*border-left: 1px solid #787878;*/
}

.first-tab {
  /*border-left: none !important;*/
}

.tab .post1 {
  width: 100% !important;
}

#tabs .tab img {
  display: inline !important;
}

#tabs .post1_img_wrapper {
  height: 96px !important;
}

.post1 p {
  margin-bottom: 5px !important;
}

.grecaptcha-badge {
  position: relative !important;
  float: left !important;
  left: 0 !important;
}

@media(min-width: 700px) {
  .grecaptcha-badge {
    margin-left: -300px;
  }
}

@media (min-width: 979px) {
}

@media (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }

  #header .header_1 .h2_1 h2 {
    font: 700 30px/35px "Lato", sans-serif;
  }

  #phone-email {
    margin-left: 30px;
  }
}

@media (max-width: 767px) {
  #tabs .post1_img_wrapper {
    height: 65px !important;
  }

  .ui-tabs-active img {
    margin-top: -65px !important;
  }

  .hide-mobile {
    display: none !important;
  }

  .mobile-dark-bg {
    background: rgba(0, 0, 0, 0.9);
  }

  .order-a-casino {
    padding-top: 20px;
  }

  #stuck_container {
    display: none;
  }

  .call-us {
    display: none;
  }

  #header .header_1 .h2_1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  #header .header_1 .h2_1 h2 {
    font-size: 12px;
  }

  .grid_4 {
    width: 30%;
    display: inline-block;
    background: rgba(0, 0, 0, 0.9);
  }

  .grid_6 {
    /*margin-bottom: 25px;*/
  }

  .form-group input,
  .form-group button,
  .form-group textarea {
    margin-left: 0;
  }

  main {
    margin-top: 0;
  }

  h2 {
    font: 700 'Lato', sans-serif;
  }

  .h2_1 .h2_1_span {
    font-size: 12px;
    font: "Roboto", sans-serif !important;
    font-weight: 100;
  }

  .header-icon {
    margin-right: 5px;
    margin-top: 5px;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .tab {
    width: 55px !important;
  }

  .tab .post1 {
    min-height: 36px;
	padding: 0 5px !important;
  }
}

@media (max-width: 479px) {
  .container {
    width: 100% !important;
	padding: 0 2rem !important;
  }

  #tabs .post1_img_wrapper {
    height: 42px !important;
  }

  .ui-tabs-active img {
    margin-top: -42px !important;
  }


  #header .header_1 .h2_1 h2 {
    font-size: 10px;
  }

  .h2_1 .h2_1_span {
    font-size: 10px;
  }

  .social-media-icon {
    margin: 2px;
  }
}
